.tagGroupItemView {
  overflow: visible;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0rem 0rem 1rem 0rem;
  > * {
    margin-top: 1rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(255, 255, 255);

  .divider {
    align-self: stretch;
    flex: 0 0 auto;
    height: 0.0625rem;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .topContent {
    overflow: visible;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    > * {
      margin-left: 0.625rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .tagGroup {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .buttonGroup {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-left: 1rem;
    }
    align-self: center;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .dropDown{
    ::after{
      display: none;
    }
    .contextMenu {
      transform: translate3d(-119px, 0px, 0px) !important;
      padding: 0px;
    }
  }

}
.tagGroupItemEdit {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0rem 0rem 1rem 0rem;
  > * {
    margin-top: 1.5rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(255, 255, 255);

  .divider {
    align-self: stretch;
    flex: 0 0 auto;
    height: 0.0625rem;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .tagGroupInput {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.25rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .tagTypeItemList {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 1.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .addTagType {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0.9375rem 0.5rem 0.5rem;
    > * {
      margin-left: 0.5rem;
    }
    align-self: flex-start;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}