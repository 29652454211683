@import "reset.css";
@import "~react-bootstrap-typeahead/css/Typeahead.css";
@import '~react-quill/dist/quill.snow.css';

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html, body {
    height: 100%;
}

#root {
    height: 100%;
}


/* updated quill styling */
.quill {
    width: 100%;
}

.ql-editor {
    height: 200px;
}

.ql-toolbar {
    border: 1px solid #ced4da;
    border-radius: 4px 4px 0 0;
}

.ql-container {
    height: initial;
    min-height: 12.5rem;
    border: 1px solid #ced4da;
    border-radius: 0 0 4px 4px;
}
