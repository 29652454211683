.coursesPage {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 3.5rem 0rem 4.5rem 0rem;

  .coursesBlock {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 1rem 1rem 1rem;
    > * {
      margin-top: 1rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
  }

}

@media (min-width: 1440px) {
  .coursesPage {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 2rem 0rem 4.5rem 0rem;
  
    .coursesBlock {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 2.5rem 2.5rem 2.5rem 2.5rem;
      > * {
        margin-top: 1.5rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
    }
  
  }
}

@media (min-width: 1920px) {
  .coursesPage {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 0rem 4.5rem 0rem;
  
    .coursesBlock {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 5rem 5rem 5rem 5rem;
      > * {
        margin-top: 2rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
    }
  
  }
}