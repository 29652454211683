.uploadImageFieldDefault {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-top: 0.25rem;
  }
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .label {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .uploadButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > * {
      margin-top: 0.25rem;
    }
    align-self: flex-start;
    width: 12.5rem;
    flex: 0 0 8.25rem;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}
.uploadImageFieldUploaded {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-top: 0.25rem;
  }
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .label {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .uploadedImage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > * {
      margin-top: 0.25rem;
    }
    align-self: flex-start;
    width: 12.5rem;
    flex: 0 0 8.25rem;
    &:nth-child(1) {
      margin-top: 0;
    }
    background-color: rgb(245, 245, 245);
    border-radius: 4px;
  }

  .buttonContainer {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.75rem 0rem 0rem 0rem;
    align-self: flex-start;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .buttonGroup {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-left: 0.75rem;
    }
    align-self: flex-start;
    flex: 0 0 auto;
  }

}