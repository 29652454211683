.mainMenuItemList {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0.5rem 0rem 0.5rem;

  .mainMenuItem {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    flex: 0 0 3rem;
  }

}