.courseCardList {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-top: 1rem;
    margin-left: 1rem;
  }

  .courseCard {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 1 1 auto;
    min-width: calc(33.333333333333336% - 0.6666666666666666rem);
    max-width: calc(33.333333333333336% - 0.6666666666666666rem);
    @media (max-width: 1439px) {
      &:nth-child(-n+3) {
        margin-top: 0;
      }
      &:nth-child(3n+1) {
        margin-left: 0;
      }
    }
  }

}

@media (min-width: 1440px) {
  .courseCardList {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 2rem;
      margin-left: 2rem;
    }
  
    .courseCard {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      min-width: calc(33.333333333333336% - 1.3333333333333333rem);
      max-width: calc(33.333333333333336% - 1.3333333333333333rem);
      @media (max-width: 1919px) {
        &:nth-child(-n+3) {
          margin-top: 0;
        }
        &:nth-child(3n+1) {
          margin-left: 0;
        }
      }
    }
  
  }
}

@media (min-width: 1920px) {
  .courseCardList {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 2.5rem;
      margin-left: 2.5rem;
    }
  
    .courseCard {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      min-width: calc(33.333333333333336% - 1.6666666666666667rem);
      max-width: calc(33.333333333333336% - 1.6666666666666667rem);
      &:nth-child(-n+3) {
        margin-top: 0;
      }
      &:nth-child(3n+1) {
        margin-left: 0;
      }
    }
  
  }
}