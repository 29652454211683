.iconVimeoDefault {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(70%) sepia(33%) saturate(3596%) hue-rotate(159deg) brightness(91%) contrast(102%);
  }

}
.iconYoutubeDefault {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(19%) sepia(98%) saturate(5039%) hue-rotate(356deg) brightness(101%) contrast(126%);
  }

}
.iconGridDark {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(11%) sepia(5%) saturate(224%) hue-rotate(201deg) brightness(98%) contrast(91%);
  }

}
.iconMoreVerticalDark {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(12%) sepia(0%) saturate(2377%) hue-rotate(277deg) brightness(97%) contrast(91%);
  }

}
.iconMoreVerticalBlue {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(28%) sepia(94%) saturate(1493%) hue-rotate(191deg) brightness(99%) contrast(106%);
  }

}
.iconBarChartDark {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(10%) sepia(6%) saturate(237%) hue-rotate(202deg) brightness(97%) contrast(87%);
  }

}
.iconStarDark {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.4375rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(11%) sepia(0%) saturate(1589%) hue-rotate(80deg) brightness(92%) contrast(89%);
  }

}
.iconPlusDark {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(10%) sepia(8%) saturate(190%) hue-rotate(202deg) brightness(90%) contrast(88%);
  }

}
.iconPlusBlue {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(30%) sepia(58%) saturate(7196%) hue-rotate(197deg) brightness(101%) contrast(101%);
  }

}
.iconPlusBrand {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(21%) sepia(98%) saturate(5286%) hue-rotate(12deg) brightness(91%) contrast(101%);
  }

}
.iconPlusDisabled {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(87%) sepia(0%) saturate(538%) hue-rotate(248deg) brightness(87%) contrast(95%);
  }

}
.iconPlusLight {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(67deg) brightness(108%) contrast(101%);
  }

}
.iconUploadBlue {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(30%) sepia(88%) saturate(2509%) hue-rotate(193deg) brightness(92%) contrast(104%);
  }

}
.iconExternalLinkBlue {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(47%) sepia(86%) saturate(5616%) hue-rotate(194deg) brightness(92%) contrast(101%);
  }

}
.iconVideoReelDark {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(13%) sepia(8%) saturate(7%) hue-rotate(240deg) brightness(94%) contrast(94%);
  }

}
.iconArrowLeftDark {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(11%) sepia(9%) saturate(130%) hue-rotate(202deg) brightness(101%) contrast(91%);
  }

}
.iconExitDark {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(8%) sepia(0%) saturate(5181%) hue-rotate(293deg) brightness(100%) contrast(84%);
  }

}
.iconArrowUpDark {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(9%) sepia(0%) saturate(1757%) hue-rotate(299deg) brightness(98%) contrast(87%);
  }

}
.iconArrowDownDark {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(11%) sepia(6%) saturate(204%) hue-rotate(202deg) brightness(97%) contrast(89%);
  }

}
.iconSettingsDark {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(9%) sepia(15%) saturate(77%) hue-rotate(201deg) brightness(98%) contrast(88%);
  }

}
.iconExpandDark {
  align-self: center;
  height: 1.4375rem;
  flex: 0 0 1.4375rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(11%) sepia(64%) saturate(20%) hue-rotate(202deg) brightness(94%) contrast(92%);
  }

}
.iconCollapseDark {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(9%) sepia(7%) saturate(243%) hue-rotate(202deg) brightness(91%) contrast(84%);
  }

}
.iconPriceTagDark {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(9%) sepia(4%) saturate(339%) hue-rotate(201deg) brightness(95%) contrast(86%);
  }

}
.iconCloudUploadDark {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(10%) sepia(12%) saturate(113%) hue-rotate(201deg) brightness(95%) contrast(88%);
  }

}
.iconCloseDark {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(22%) sepia(0%) saturate(3715%) hue-rotate(46deg) brightness(70%) contrast(102%);
  }

}
.iconUndoDark {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(11%) sepia(0%) saturate(1381%) hue-rotate(68deg) brightness(95%) contrast(89%);
  }

}
.iconPaperPlaneDark {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(7%) sepia(0%) saturate(1520%) hue-rotate(76deg) brightness(99%) contrast(82%);
  }

}
.iconCornerUpRightDark {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(11%) sepia(7%) saturate(219%) hue-rotate(202deg) brightness(87%) contrast(88%);
  }

}
.iconPlusCircleBlue {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(32%) sepia(87%) saturate(4608%) hue-rotate(196deg) brightness(98%) contrast(101%);
  }

}
.iconPlusCircleBrand {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(16%) sepia(61%) saturate(5828%) hue-rotate(13deg) brightness(99%) contrast(101%);
  }

}
.iconFileGrey {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(35%) sepia(18%) saturate(11%) hue-rotate(28deg) brightness(101%) contrast(94%);
  }

}
.iconFileBrand {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(19%) sepia(73%) saturate(3252%) hue-rotate(1deg) brightness(102%) contrast(107%);
  }

}
.iconVideoGrey {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(44%) sepia(0%) saturate(1249%) hue-rotate(136deg) brightness(85%) contrast(94%);
  }

}
.iconVideoBrand {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(22%) sepia(96%) saturate(5704%) hue-rotate(13deg) brightness(92%) contrast(101%);
  }

}
.iconBookGrey {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(36%) sepia(0%) saturate(22%) hue-rotate(147deg) brightness(102%) contrast(92%);
  }

}
.iconBookBrand {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(18%) sepia(100%) saturate(4290%) hue-rotate(15deg) brightness(92%) contrast(105%);
  }

}
.iconFileTimeGrey {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(40%) sepia(2%) saturate(14%) hue-rotate(333deg) brightness(92%) contrast(89%);
  }

}
.iconFileTimeBrand {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(18%) sepia(64%) saturate(5999%) hue-rotate(15deg) brightness(93%) contrast(105%);
  }

}
.iconCodeGrey {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(38%) sepia(0%) saturate(0%) hue-rotate(188deg) brightness(94%) contrast(86%);
  }

}
.iconCodeBrand {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(16%) sepia(77%) saturate(6543%) hue-rotate(21deg) brightness(97%) contrast(104%);
  }

}
.iconFileAddGrey {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(41%) sepia(0%) saturate(121%) hue-rotate(156deg) brightness(88%) contrast(89%);
  }

}
.iconFileAddDark {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(9%) sepia(0%) saturate(2472%) hue-rotate(27deg) brightness(90%) contrast(84%);
  }

}
.iconChevronUpDark {
  align-self: center;
  height: 1.4375rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(14%) sepia(0%) saturate(2870%) hue-rotate(159deg) brightness(100%) contrast(100%);
  }

}
.iconChevronUpBlue {
  align-self: center;
  height: 1.4375rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(38%) sepia(87%) saturate(3295%) hue-rotate(191deg) brightness(88%) contrast(105%);
  }

}
.iconChevronUpBrand {
  align-self: center;
  height: 1.4375rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(20%) sepia(53%) saturate(5275%) hue-rotate(6deg) brightness(90%) contrast(103%);
  }

}
.iconChevronUpDisabled {
  align-self: center;
  height: 1.4375rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(83%) sepia(0%) saturate(2036%) hue-rotate(315deg) brightness(88%) contrast(105%);
  }

}
.iconChevronDownDark {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(12%) sepia(8%) saturate(152%) hue-rotate(202deg) brightness(95%) contrast(93%);
  }

}
.iconChevronDownBlue {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(30%) sepia(50%) saturate(5573%) hue-rotate(195deg) brightness(97%) contrast(102%);
  }

}
.iconChevronDownBrand {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(14%) sepia(91%) saturate(6458%) hue-rotate(21deg) brightness(98%) contrast(100%);
  }

}
.iconChevronDownDisabled {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(83%) sepia(0%) saturate(3%) hue-rotate(45deg) brightness(92%) contrast(88%);
  }

}
.iconMoreAppsDark {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(7%) sepia(1%) saturate(4218%) hue-rotate(251deg) brightness(91%) contrast(82%);
  }

}
.iconMoreAppsBlue {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(31%) sepia(61%) saturate(2890%) hue-rotate(193deg) brightness(92%) contrast(103%);
  }

}
.iconBellDark {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.4375rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(11%) sepia(9%) saturate(40%) hue-rotate(202deg) brightness(93%) contrast(90%);
  }

}
.iconBellBlue {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.4375rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(35%) sepia(88%) saturate(4056%) hue-rotate(194deg) brightness(95%) contrast(101%);
  }

}
.iconCircleDefault {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(11%) sepia(0%) saturate(3180%) hue-rotate(269deg) brightness(91%) contrast(89%);
  }

}
.iconCircleCheckBlue {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(33%) sepia(30%) saturate(6499%) hue-rotate(194deg) brightness(93%) contrast(101%);
  }

}
.iconSquareDark {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(11%) sepia(6%) saturate(237%) hue-rotate(202deg) brightness(91%) contrast(90%);
  }

}
.iconSquareDisabled {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(71%) sepia(74%) saturate(1%) hue-rotate(314deg) brightness(89%) contrast(104%);
  }

}
.iconSquareCheckBlue {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(36%) sepia(71%) saturate(5240%) hue-rotate(195deg) brightness(94%) contrast(101%);
  }

}
.iconSquareCheckDisabled {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(90%) sepia(0%) saturate(1482%) hue-rotate(74deg) brightness(87%) contrast(92%);
  }

}
.iconDragHandleDark {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(12%) sepia(3%) saturate(58%) hue-rotate(202deg) brightness(99%) contrast(94%);
  }

}
.iconDragHandleGrey {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(39%) sepia(1%) saturate(0%) hue-rotate(356deg) brightness(92%) contrast(86%);
  }

}
.iconClearFilledDark {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(7%) sepia(2%) saturate(1305%) hue-rotate(202deg) brightness(89%) contrast(81%);
  }

}
.iconClearFilledBrand {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(19%) sepia(43%) saturate(6300%) hue-rotate(7deg) brightness(96%) contrast(104%);
  }

}
.iconCircleChevronUpGrey {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(40%) sepia(9%) saturate(0%) hue-rotate(142deg) brightness(90%) contrast(90%);
  }

}
.iconCircleChevronDownGrey {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(34%) sepia(3%) saturate(9%) hue-rotate(353deg) brightness(105%) contrast(84%);
  }

}
.iconBellDotDark {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(10%) sepia(1%) saturate(3365%) hue-rotate(221deg) brightness(104%) contrast(90%);
  }

  .vector {
    width: 100%;
    height: 100%;
  }

  .ellipse1 {
    width: 100%;
    height: 100%;
    background-color: rgb(216, 36, 0);
  }

}
.iconBellDotBlue {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
  }

  .vector {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7484%) hue-rotate(274deg) brightness(115%) contrast(100%);
  }

  .ellipse1 {
    width: 100%;
    height: 100%;
    background-color: rgb(216, 36, 0);
  }

}
.iconUploadImageBlue {
  align-self: center;
  height: 1.5rem;
  flex: 0 0 1.5rem;

  .content {
    width: 100%;
    height: 100%;
  }

  .plus {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(31%) sepia(96%) saturate(1627%) hue-rotate(192deg) brightness(89%) contrast(102%);
    border: 1px solid rgb(245, 245, 245);
  }

}