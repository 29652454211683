.tagGroupItemList {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .tagGroupItem {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 0rem 1rem 0rem;
    > * {
      margin-top: 1.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
  }

}