.tableHeader {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-top: 0.625rem;
  }

  .tableItem {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    > * {
      margin-left: 0.75rem;
    }
    align-self: stretch;
    flex: 0 0 3rem;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .type {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.75rem 0.75rem 0.75rem 0rem;
    > * {
      margin-left: 0.25rem;
    }
    align-self: center;
    flex: 0 0 8.3125rem;
    &:nth-child(1) {
      margin-left: 0;
    }
    border-radius: 4px;
  }

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .name {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.75rem 0.75rem 0.75rem 0rem;
    > * {
      margin-left: 0.25rem;
    }
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
    border-radius: 4px;
  }

  .linkOrFile {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.75rem 0.75rem 0.75rem 0rem;
    > * {
      margin-left: 0.25rem;
    }
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
    border-radius: 4px;
  }

  .emptyFrame {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    align-self: center;
    height: 2rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-left: 0;
    }
    border-radius: 4px;
  }

}