.textArea {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  > * {
    margin-top: 0.25rem;
  }
  background-color: rgb(245, 245, 245);
  border-radius: 4px;

  .text {
    align-self: stretch;
    flex: 1;
    &:nth-child(1) {
      margin-top: 0;
    }
    font-family: 'Inter', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(30, 30, 30);
  }

}
.textArea:selected {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  > * {
    margin-top: 0.25rem;
  }
  background-color: rgb(245, 245, 245);
  border: 1px solid rgb(0, 117, 225);
  border-radius: 4px;

  .text {
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
    font-family: 'Inter', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(30, 30, 30);
  }

}
.textArea:error {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  > * {
    margin-top: 0.25rem;
  }
  background-color: rgb(245, 245, 245);
  border: 1px solid rgb(216, 36, 0);
  border-radius: 4px;

  .text {
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
    font-family: 'Inter', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(30, 30, 30);
  }

}
.textArea:disabled {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  > * {
    margin-top: 0.25rem;
  }
  background-color: rgb(232, 231, 228);
  border-radius: 4px;

  .text {
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
    font-family: 'Inter', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(158, 158, 158);
  }

}