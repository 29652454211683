.tagTypeItem {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-top: 1.5rem;
  }

  .divider {
    align-self: stretch;
    flex: 0 0 auto;
    height: 0.0625rem;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .tagTypeSection {
    display: flex;
    flex-direction: row;
    align-self: stretch;
    align-content: center;
    align-items: center;

    .tagTypeField {
      flex: auto;
    }

    .deleteTagTypeButton {
      display: flex;
      height: auto;
      align-self: flex-end;
      padding: 0.75rem 0.75rem 0.75rem 0.75rem;
      img{
        filter: brightness(0) saturate(100%) invert(30%) sepia(58%) saturate(7196%) hue-rotate(0deg) brightness(101%) contrast(101%);
      }
    }
  }

  .tagValueField {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.25rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}