.videoUploadSectionDefault {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .videoUrl {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.25rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
  }

}
.videoUploadSectionUpload {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-top: 1.5rem;
  }
  align-self: flex-start;
  width: 58rem;
  flex: 0 0 26.75rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .uploadField {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.25rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .videoThumbnail {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.25rem;
    }
    align-self: flex-start;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}