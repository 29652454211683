.select {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  > * {
    margin-left: 0.25rem;
  }
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(232, 231, 228);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .icon {
    align-self: flex-start;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.select:hover {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  > * {
    margin-left: 0.25rem;
  }
  background-color: rgb(245, 245, 245);
  border: 1px solid rgb(232, 231, 228);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .icon {
    align-self: flex-start;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.select:focused {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  > * {
    margin-left: 0.25rem;
  }
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(0, 117, 225);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .icon {
    align-self: flex-start;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.select:disabled {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  > * {
    margin-left: 0.25rem;
  }
  background-color: rgb(245, 245, 245);
  border: 1px solid rgb(232, 231, 228);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .icon {
    align-self: flex-start;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}