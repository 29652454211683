.courseCard {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 17rem;
  max-height: 17rem;
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05000000074505806);

  .container {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1.25rem 1.25rem 1.25rem 1.25rem;
    > * {
      margin-top: 0.625rem;
    }
    :first-child {
      margin-top: 0;
    }
    align-self: stretch;
    flex: 0 0 auto;
  }

  .title {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    --lh: 2rem;
    --max-lines: 3;
    max-height: calc(var(--lh) * var(--max-lines));
    @media (max-width: 1439px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .metadataContainer {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.25rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1439px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .units {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1439px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .estimatedTime {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1439px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .description {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1439px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    p {
      overflow-wrap: break-word;
      word-wrap: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      --lh: 1rem;
      --max-lines: 3;
      max-height: calc(var(--lh) * var(--max-lines));
    }
  }

  .button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1rem 0.5rem 1rem;
    align-self: flex-start;
    flex: 0 0 auto;
    @media (max-width: 1439px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

}

@media (min-width: 1440px) {
  .courseCard {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 17rem;
    max-height: 17rem;
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05000000074505806);
  
    .container {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1.75rem 1.75rem 1.75rem 1.75rem;
      > * {
        margin-top: 0.75rem;
      }
      :first-child {
        margin-top: 0;
      }
      align-self: stretch;
      flex: 0 0 auto;
    }
  
    .title {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1919px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .metadataContainer {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.25rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1919px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .units {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1919px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .estimatedTime {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1919px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .description {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 1919px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
      p {
        overflow-wrap: break-word;
        word-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
      }
    }
  
    .button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0.5rem 1rem 0.5rem 1rem;
      align-self: flex-start;
      flex: 0 0 auto;
      @media (max-width: 1919px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
  }
}

@media (min-width: 1920px) {
  .courseCard {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 17rem;
    max-height: 17rem;
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05000000074505806);
  
    .container {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1.75rem 1.75rem 1.75rem 1.75rem;
      > * {
        margin-top: 1rem;
      }
      :first-child {
        margin-top: 0;
      }
      align-self: stretch;
      flex: 0 0 auto;
    }
  
    .title {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .metadataContainer {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.25rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .units {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .estimatedTime {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .description {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
      p {
        overflow-wrap: break-word;
        word-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
      }
    }
  
    .button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0.5rem 1rem 0.5rem 1rem;
      align-self: flex-start;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
  }
}

@media (max-width: 1024) {
  .courseCard {
    min-height: 18rem;
  }
}

@media (max-width: 768px) {
  .courseCard {
    min-height: 20rem;
  }
}