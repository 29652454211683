.userProfile {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  > * {
    margin-left: 0.5rem;
  }

  .userIcon {
    align-self: center;
    height: 2rem;
    flex: 0 0 2rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .arrowIcon {
    align-self: center;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}