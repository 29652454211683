.userIconInitials {

  .background {
    width: 100%;
    height: 100%;
  }

  .value {
    width: 100%;
    height: 100%;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.9000000357627869rem;
    font-weight: 600;
    line-height: 1.2000000476837158rem;
    text-align: center;
    color: rgb(255, 255, 255);
  }

}
.userIconImage {

  .userIcon {
    width: 100%;
    height: 100%;
  }

}