.courseHeader {
  z-index: 1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 0.9375rem 0.5rem 0.9375rem;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05000000074505806);

  .button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1rem 0.5rem 1rem;
    align-self: center;
    flex: 0 0 auto;
  }

  .publishButton {
    padding: 4px 16px;
    margin-left: auto;
  }

}