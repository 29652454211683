.menuLessonSelectedCollapsed {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(245, 245, 245);

  .selector {
    align-self: center;
    min-height: 2.5rem;
    flex: 0 0 0.25rem;
    background-color: rgb(216, 36, 0);
  }

  .content {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.75rem 0.75rem 0.75rem 0.5rem;
    > * {
      margin-left: 0.625rem;
    }
    align-self: stretch;
    flex: 1 1 auto;
  }

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .selectedCollapsedIcon {
    align-self: center;
    height: 1rem;
    flex: 0 0 1rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.menuLessonSelectedExpanded {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(245, 245, 245);

  .selector {
    align-self: center;
    min-height: 2.5rem;
    flex: 0 0 0.25rem;
    background-color: rgb(216, 36, 0);
  }

  .content {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.75rem 0.75rem 0.75rem 0.5rem;
    > * {
      margin-left: 0.625rem;
    }
    align-self: stretch;
    flex: 1 1 auto;
  }

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .selectedExpandedIcon {
    align-self: center;
    height: 1rem;
    flex: 0 0 1rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.menuLessonDefaultCollapsed {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(255, 255, 255);

  .content {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    > * {
      margin-left: 0.625rem;
    }
    align-self: stretch;
    flex: 1 1 auto;
  }

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .collapsedIcon {
    align-self: center;
    height: 1rem;
    flex: 0 0 1rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.menuLessonDefaultCollapsed:focused {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(0, 0, 0);

  .content {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    > * {
      margin-left: 0.625rem;
    }
    align-self: stretch;
    flex: 1 1 auto;
  }

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .collapsedIcon {
    align-self: center;
    height: 1rem;
    flex: 0 0 1rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.menuLessonDefaultCollapsed:hover {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(232, 231, 228);

  .content {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    > * {
      margin-left: 0.625rem;
    }
    align-self: stretch;
    flex: 1 1 auto;
  }

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .collapsedIcon {
    align-self: center;
    height: 1rem;
    flex: 0 0 1rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.menuLessonDefaultCollapsed:pressed {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(232, 231, 228);

  .content {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    > * {
      margin-left: 0.625rem;
    }
    align-self: stretch;
    flex: 1 1 auto;
  }

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .collapsedIcon {
    align-self: center;
    height: 1rem;
    flex: 0 0 1rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.menuLessonDefaultExpanded {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(255, 255, 255);

  .content {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    > * {
      margin-left: 0.625rem;
    }
    align-self: stretch;
    flex: 1 1 auto;
  }

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .expandedIcon {
    align-self: center;
    height: 1rem;
    flex: 0 0 1rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.menuLessonDefaultExpanded:focused {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(0, 0, 0);

  .content {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    > * {
      margin-left: 0.625rem;
    }
    align-self: stretch;
    flex: 1 1 auto;
  }

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .expandedIcon {
    align-self: center;
    height: 1rem;
    flex: 0 0 1rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.menuLessonDefaultExpanded:hover {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(232, 231, 228);

  .content {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    > * {
      margin-left: 0.625rem;
    }
    align-self: stretch;
    flex: 1 1 auto;
  }

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .expandedIcon {
    align-self: center;
    height: 1rem;
    flex: 0 0 1rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.menuLessonDefaultExpanded:pressed {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(232, 231, 228);

  .content {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    > * {
      margin-left: 0.625rem;
    }
    align-self: stretch;
    flex: 1 1 auto;
  }

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .expandedIcon {
    align-self: center;
    height: 1rem;
    flex: 0 0 1rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}