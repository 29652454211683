.radioButtonList {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-top: 0.75rem;
    margin-left: 0.75rem;
  }

  .radioButton {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.625rem 1rem 0.625rem 0.875rem;
    > * {
      margin-left: 0.5rem;
    }
    align-self: flex-start;
    flex: 0 0 auto;
    &:nth-child(-n+5) {
      margin-top: 0;
    }
    &:nth-child(5n+1) {
      margin-left: 0;
    }
  }

}