.checkboxUnchecked {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .icon {
    align-self: center;
    height: 1.5rem;
    flex: 0 0 1.5rem;
  }

}
.checkboxChecked {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .icon {
    align-self: center;
    height: 1.5rem;
    flex: 0 0 1.5rem;
  }

}
.checkboxChecked:disabled {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .icon {
    align-self: center;
    height: 1.5rem;
    flex: 0 0 1.5rem;
  }

}
.checkboxUnchecked:disabled {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .icon {
    align-self: center;
    height: 1.5rem;
    flex: 0 0 1.5rem;
  }

}