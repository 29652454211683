.header {
  z-index: 1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem 1.25rem 1.25rem 1.25rem;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05000000074505806);

  .logo {
    align-self: center;
    height: 2rem;
    flex: 0 0 5.1875rem;
  }

  .headerContent {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    > * {
      margin-left: 1.5rem;
    }
    align-self: center;
    flex: 0 0 auto;
  }

}