.uploadButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > * {
    margin-top: 0.25rem;
  }
  background-color: rgb(245, 245, 245);
  border-radius: 4px;

  .icon {
    align-self: center;
    width: 1.5rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    width: 6.125rem;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}
.uploadButton:hover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > * {
    margin-top: 0.25rem;
  }
  background-color: rgb(232, 231, 228);
  border-radius: 4px;

  .icon {
    align-self: center;
    width: 1.5rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    width: 6.125rem;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}
.uploadButton:pressed {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > * {
    margin-top: 0.25rem;
  }
  background-color: rgb(232, 231, 228);
  border-radius: 4px;

  .icon {
    align-self: center;
    width: 1.5rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    width: 6.125rem;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}
.uploadButton:focus {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > * {
    margin-top: 0.25rem;
  }
  background-color: rgb(245, 245, 245);
  border: 1px solid rgb(0, 117, 225);
  border-radius: 4px;

  .icon {
    align-self: center;
    width: 1.5rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    width: 6.125rem;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}