.mainMenuHeader {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.625rem 0.625rem 0.625rem 1.25rem;
  > * {
    margin-left: 0.75rem;
  }
  background-color: rgb(255, 255, 255);

  .appIcon {
    height: 40px;
    width: 40px;
    background: #333;
    align-self: center;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
    border-radius: 8px;
  }

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}