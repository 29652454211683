.mainMenuItemDefault {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  border-radius: 4px;

  .container {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 0.75rem 1rem 0.75rem;
    > * {
      margin-left: 0.75rem;
    }
    align-self: stretch;
    flex: 1 1 auto;
  }

  .icon {
    align-self: center;
    height: 1rem;
    flex: 0 0 1rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.mainMenuItemSelected {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgb(245, 245, 245);
  border-radius: 4px;

  .selector {
    align-self: stretch;
    height: 3rem;
    flex: 0 0 0.25rem;
    background-color: rgb(216, 36, 0);
  }

  .container {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 0.75rem 1rem 0.5rem;
    > * {
      margin-left: 0.75rem;
    }
    align-self: stretch;
    flex: 1 1 auto;
  }

  .icon {
    align-self: center;
    height: 1rem;
    flex: 0 0 1rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.mainMenuItemDefault:hover {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgb(232, 231, 228);
  border-radius: 4px;

  .container {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 0.75rem 1rem 0.75rem;
    > * {
      margin-left: 0.75rem;
    }
    align-self: stretch;
    flex: 1 1 auto;
  }

  .icon {
    align-self: center;
    height: 1rem;
    flex: 0 0 1rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.mainMenuItemDefault:pressed {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgb(232, 231, 228);
  border-radius: 4px;

  .container {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 0.75rem 1rem 0.75rem;
    > * {
      margin-left: 0.75rem;
    }
    align-self: stretch;
    flex: 1 1 auto;
  }

  .icon {
    align-self: center;
    height: 1rem;
    flex: 0 0 1rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.mainMenuItemDefault:focused {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(30, 30, 30);
  border-radius: 4px;

  .container {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 0.75rem 1rem 0.75rem;
    > * {
      margin-left: 0.75rem;
    }
    align-self: stretch;
    flex: 1 1 auto;
  }

  .icon {
    align-self: center;
    height: 1rem;
    flex: 0 0 1rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}