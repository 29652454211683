.tableItemFileSelectedFileUpload {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  > * {
    margin-left: 0.75rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .resourceTypeSelector {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    > * {
      margin-left: 0.25rem;
    }
    align-self: center;
    flex: 0 0 9.0625rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .resourceNameInput {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    > * {
      margin-top: 0.25rem;
    }
    align-self: center;
    flex: 1;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .selectFileItem {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.25rem 0.75rem 0.25rem 0.75rem;
    > * {
      margin-left: 0.5rem;
    }
    align-self: stretch;
    flex: 1;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .moreButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    align-self: center;
    height: 2.5rem;
    flex: 0 0 2.5rem;
    &:nth-child(1) {
      margin-left: 0;
    }

  }

  .dropDown {
    ::after {
      display: none;
    }
    .contextMenu {
      transform: translate3d(-119px, 0px, 0px) !important;
      padding: 0px;
    }
  }

}
.tableItemNoFileSelectedFileUpload {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  > * {
    margin-left: 0.75rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .resourceTypeSelector {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    > * {
      margin-left: 0.25rem;
    }
    align-self: center;
    flex: 0 0 9.0625rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .resourceNameInput {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    > * {
      margin-top: 0.25rem;
    }
    align-self: center;
    flex: 1;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .selectFileItem {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0.25rem 0.75rem 0.25rem 0.75rem;
    > * {
      margin-left: 0.5rem;
    }
    align-self: center;
    flex: 1;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .moreButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    align-self: center;
    height: 2.5rem;
    flex: 0 0 2.5rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .dropDown {
    ::after {
      display: none;
    }
    .contextMenu {
      transform: translate3d(-119px, 0px, 0px) !important;
      padding: 0px;
    }
  }

}
.tableItemDefaultExternalLink {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  > * {
    margin-left: 0.75rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .resourceTypeSelector {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    > * {
      margin-left: 0.25rem;
    }
    align-self: center;
    flex: 0 0 9.0625rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .resourceNameInput {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    > * {
      margin-top: 0.25rem;
    }
    align-self: center;
    flex: 1;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .linkInput {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    > * {
      margin-top: 0.25rem;
    }
    align-self: center;
    flex: 1;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .moreButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    align-self: center;
    height: 2.5rem;
    flex: 0 0 2.5rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .dropDown {
    ::after {
      display: none;
    }
    .contextMenu {
      transform: translate3d(-119px, 0px, 0px) !important;
      padding: 0px;
    }
  }

}