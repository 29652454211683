.spinner {
  height: 24px;
  width: 24px;
}

.buttonIconButtonLargeColourBrand {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(0, 117, 225);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 1 1 auto;
  }

  .spinner {
    border: 0.25em solid rgb(255, 255, 255);
    border-right-color: transparent;
  }
}
.buttonIconButtonMediumColourBrand {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(0, 117, 225);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 1 1 auto;
  }

  .spinner {
    border: 0.25em solid rgb(255, 255, 255);
    border-right-color: transparent;
  }
}
.buttonIconButtonLargeColourBrand:disabled {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(200, 228, 255);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 1 1 auto;
  }

  .spinner {
    border: 0.25em solid rgb(255, 255, 255);
    border-right-color: transparent;
  }
}
.buttonIconButtonMediumColourBrand:disabled {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(200, 228, 255);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 1 1 auto;
  }

  .spinner {
    border: 0.25em solid rgb(255, 255, 255);
    border-right-color: transparent;
  }
}
.buttonIconButtonLargeColourBrand:focused {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(0, 117, 225);
  border: 2px solid rgb(200, 228, 255);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 1 1 auto;
  }
}
.buttonIconButtonMediumColourBrand:focused {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(0, 117, 225);
  border: 2px solid rgb(200, 228, 255);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 1 1 auto;
  }
}
.buttonIconButtonLargeColourBrand:hover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(0, 85, 163);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 1 1 auto;
  }
}
.buttonIconButtonMediumColourBrand:hover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(0, 85, 163);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 1 1 auto;
  }

}
.buttonTextButtonLargeColourBrand:focused {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  background-color: rgb(0, 117, 225);
  border: 2px solid rgb(200, 228, 255);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 0 0 auto;
  }

}
.buttonTextButtonLargeBasicBasic:focused {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  background-color: rgb(232, 231, 228);
  border: 2px solid rgb(158, 158, 158);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 0 0 auto;
  }

}
.buttonTextButtonMediumColourBrand:focused {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: rgb(0, 117, 225);
  border: 2px solid rgb(200, 228, 255);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextButtonMediumBasicBasic:focused {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: rgb(232, 231, 228);
  border: 2px solid rgb(158, 158, 158);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextButtonLargeColourBrand {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  background-color: rgb(0, 117, 225);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

  .spinner {
    border: 0.25em solid rgb(255, 255, 255);
    border-right-color: transparent;
  }
}
.buttonTextButtonMediumColourBrand:disabled {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: rgb(200, 228, 255);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

  .spinner {
    border: 0.25em solid rgb(255, 255, 255);
    border-right-color: transparent;
  }
}
.buttonTextButtonLargeColourBrand:hover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  background-color: rgb(0, 85, 163);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextButtonLargeColourBrand:disabled {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  background-color: rgb(200, 228, 255);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

  .spinner {
    border: 0.25em solid rgb(255, 255, 255);
    border-right-color: transparent;
  }
}
.buttonTextButtonLargeBasicBasic {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  background-color: rgb(232, 231, 228);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }
}
.buttonTextButtonLargeBasicBasic:disabled {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  background-color: rgb(232, 231, 228);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextButtonLargeBasicBasic:hover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  background-color: rgb(189, 189, 189);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextButtonLargeWhiteBrand {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  background-color: rgb(245, 245, 245);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextButtonLargeWhiteBrand:hover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  background-color: rgb(232, 231, 228);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextButtonMediumColourBrand {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: rgb(0, 117, 225);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextButtonMediumColourBrand:hover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: rgb(0, 85, 163);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextButtonMediumBasicBasic {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: rgb(232, 231, 228);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextButtonMediumBasicBasic:disabled {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: rgb(232, 231, 228);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextButtonMediumBasicBasic:hover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: rgb(189, 189, 189);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextButtonMediumWhiteBrand {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: rgb(245, 245, 245);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonIconTextButtonMediumWhiteBrand {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.9375rem 0.5rem 0.5rem;
  > * {
    margin-left: 0.5rem;
  }
  background-color: rgb(245, 245, 245);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 0 0 1rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.buttonIconTextButtonMediumWhiteBrand:hover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.9375rem 0.5rem 0.5rem;
  > * {
    margin-left: 0.5rem;
  }
  background-color: rgb(232, 231, 228);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 0 0 1rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.buttonIconTextButtonMediumWhiteBrand:pressed {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.9375rem 0.5rem 0.5rem;
  > * {
    margin-left: 0.5rem;
  }
  background-color: rgb(232, 231, 228);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 0 0 1rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.buttonIconTextButtonMediumWhiteBrand:focused {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.9375rem 0.5rem 0.5rem;
  > * {
    margin-left: 0.5rem;
  }
  background-color: rgb(245, 245, 245);
  border: 2px solid rgb(0, 117, 225);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 0 0 1rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.buttonIconTextButtonMediumWhiteBrand:disabled {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.9375rem 0.5rem 0.5rem;
  > * {
    margin-left: 0.5rem;
  }
  background-color: rgb(245, 245, 245);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 0 0 1rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.buttonTextButtonLargeWhiteBrand:disabled {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  background-color: rgb(245, 245, 245);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextButtonMediumWhiteBrand:disabled {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: rgb(245, 245, 245);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextButtonLargeWhiteBrand:focused {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  background-color: rgb(245, 245, 245);
  border: 2px solid rgb(0, 117, 225);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextButtonMediumWhiteBrand:focused {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: rgb(245, 245, 245);
  border: 2px solid rgb(0, 117, 225);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextButtonMediumWhiteBrand:hover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: rgb(232, 231, 228);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextButtonLargeColourBrand:pressed {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  background-color: rgb(0, 85, 163);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 0 0 auto;
  }

}
.buttonTextButtonLargeBasicBasic:pressed {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  background-color: rgb(189, 189, 189);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextButtonMediumColourBrand:pressed {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: rgb(0, 85, 163);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonIconButtonLargeColourBrand:pressed {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(0, 85, 163);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 1 1 auto;
  }

}
.buttonIconButtonMediumColourBrand:pressed {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(0, 85, 163);
  border-radius: 4px;

  .icon {
    align-self: center;
    height: 1rem;
    flex: 0 0 1rem;
  }

}
.buttonIconButtonLargeBasicBasic {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(232, 231, 228);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 1 1 auto;
  }

}
.buttonIconButtonMediumBasicBasic {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(232, 231, 228);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 1 1 auto;
  }

}
.buttonIconButtonLargeBasicBasic:disabled {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(232, 231, 228);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 1 1 auto;
  }

}
.buttonIconButtonMediumBasicBasic:disabled {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(232, 231, 228);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 1 1 auto;
  }

}
.buttonIconButtonLargeBasicBasic:focused {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(232, 231, 228);
  border: 2px solid rgb(158, 158, 158);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 1 1 auto;
  }

}
.buttonIconButtonMediumBasicBasic:focused {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(232, 231, 228);
  border: 2px solid rgb(158, 158, 158);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 1 1 auto;
  }

}
.buttonIconButtonLargeBasicBasic:hover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(189, 189, 189);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 1 1 auto;
  }

}
.buttonIconButtonMediumBasicBasic:hover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(189, 189, 189);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 1 1 auto;
  }

}
.buttonIconButtonLargeBasicBasic:pressed {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(189, 189, 189);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 1 1 auto;
  }

}
.buttonIconButtonMediumBasicBasic:pressed {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(189, 189, 189);
  border-radius: 4px;

  .icon {
    align-self: center;
    height: 1rem;
    flex: 0 0 1rem;
  }

}
.buttonIconButtonLargeWhiteBrand {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(245, 245, 245);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 1 1 auto;
  }

}
.buttonIconButtonMediumWhiteBrand {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(245, 245, 245);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 1 1 auto;
  }

}
.buttonIconButtonLargeWhiteBrand:disabled {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(245, 245, 245);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 1 1 auto;
  }

}
.buttonIconButtonMediumWhiteBrand:disabled {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(245, 245, 245);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 1 1 auto;
  }

}
.buttonIconButtonLargeWhiteBrand:focused {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(245, 245, 245);
  border: 2px solid rgb(0, 117, 225);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 1 1 auto;
  }

}
.buttonIconButtonMediumWhiteBrand:focused {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(245, 245, 245);
  border: 2px solid rgb(0, 117, 225);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 1 1 auto;
  }

}
.buttonIconButtonLargeWhiteBrand:hover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(232, 231, 228);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 1 1 auto;
  }

}
.buttonIconButtonMediumWhiteBrand:hover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(232, 231, 228);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 1 1 auto;
  }

}
.buttonIconButtonLargeWhiteBrand:pressed {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(232, 231, 228);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 1 1 auto;
  }

}
.buttonIconButtonMediumWhiteBrand:pressed {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(232, 231, 228);
  border-radius: 4px;

  .icon {
    align-self: center;
    height: 1rem;
    flex: 0 0 1rem;
  }

}
.buttonTextButtonLargeWhiteBrand:pressed {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  background-color: rgb(232, 231, 228);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 0 0 auto;
  }

}
.buttonTextButtonMediumWhiteBrand:pressed {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: rgb(232, 231, 228);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 0 0 auto;
  }

}
.buttonTextButtonMediumBasicBasic:pressed {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: rgb(189, 189, 189);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 0 0 auto;
  }

}
.buttonIconButtonLargeColourDanger {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(216, 36, 0);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 1 1 auto;
  }

}
.buttonIconButtonMediumColourDanger {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(216, 36, 0);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 1 1 auto;
  }

}
.buttonIconButtonLargeColourDanger:disabled {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(251, 190, 150);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 1 1 auto;
  }

}
.buttonIconButtonMediumColourDanger:disabled {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(251, 190, 150);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 1 1 auto;
  }

}
.buttonIconButtonLargeColourDanger:focused {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(216, 36, 0);
  border: 2px solid rgb(251, 190, 150);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 1 1 auto;
  }

}
.buttonIconButtonMediumColourDanger:focused {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(216, 36, 0);
  border: 2px solid rgb(251, 190, 150);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 1 1 auto;
  }

}
.buttonIconButtonLargeColourDanger:hover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(155, 2, 0);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 1 1 auto;
  }

}
.buttonIconButtonMediumColourDanger:hover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(155, 2, 0);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 1 1 auto;
  }

}
.buttonTextButtonLargeColourDanger:focused {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  background-color: rgb(216, 36, 0);
  border: 2px solid rgb(251, 190, 150);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 0 0 auto;
  }

}
.buttonTextButtonMediumColourDanger:focused {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: rgb(216, 36, 0);
  border: 2px solid rgb(251, 190, 150);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextButtonLargeColourDanger {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  background-color: rgb(216, 36, 0);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextButtonLargeColourDanger:disabled {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  background-color: rgb(251, 190, 150);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextButtonMediumColourDanger:disabled {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: rgb(251, 190, 150);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextButtonLargeColourDanger:hover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  background-color: rgb(155, 2, 0);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextButtonMediumColourDanger {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: rgb(216, 36, 0);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextButtonMediumColourDanger:hover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: rgb(155, 2, 0);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextButtonLargeColourDanger:pressed {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  background-color: rgb(155, 2, 0);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 0 0 auto;
  }

}
.buttonTextButtonMediumColourDanger:pressed {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: rgb(155, 2, 0);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 0 0 auto;
  }

}
.buttonIconButtonLargeColourDanger:pressed {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(155, 2, 0);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 1 1 auto;
  }

}
.buttonIconButtonMediumColourDanger:pressed {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(155, 2, 0);
  border-radius: 4px;

  .icon {
    align-self: center;
    height: 1rem;
    flex: 0 0 1rem;
  }

}
.buttonTextButtonLargeWhiteDanger {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  background-color: rgb(245, 245, 245);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextButtonLargeWhiteDanger:hover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  background-color: rgb(232, 231, 228);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextButtonMediumWhiteDanger {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: rgb(245, 245, 245);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextButtonLargeWhiteDanger:disabled {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  background-color: rgb(245, 245, 245);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextButtonMediumWhiteDanger:disabled {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: rgb(245, 245, 245);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextButtonLargeWhiteDanger:focused {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  background-color: rgb(245, 245, 245);
  border: 2px solid rgb(216, 36, 0);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextButtonMediumWhiteDanger:focused {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: rgb(245, 245, 245);
  border: 2px solid rgb(216, 36, 0);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextButtonMediumWhiteDanger:hover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: rgb(232, 231, 228);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonIconButtonLargeWhiteDanger {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(245, 245, 245);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 1 1 auto;
  }

}
.buttonIconButtonMediumWhiteDanger {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(245, 245, 245);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 1 1 auto;
  }

}
.buttonIconButtonLargeWhiteDanger:disabled {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(245, 245, 245);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 1 1 auto;
  }

}
.buttonIconButtonMediumWhiteDanger:disabled {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(245, 245, 245);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 1 1 auto;
  }

}
.buttonIconButtonLargeWhiteDanger:focused {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(245, 245, 245);
  border: 2px solid rgb(216, 36, 0);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 1 1 auto;
  }

}
.buttonIconButtonMediumWhiteDanger:focused {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(245, 245, 245);
  border: 2px solid rgb(216, 36, 0);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 1 1 auto;
  }

}
.buttonIconButtonLargeWhiteDanger:hover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(232, 231, 228);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 1 1 auto;
  }

}
.buttonIconButtonMediumWhiteDanger:hover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(232, 231, 228);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 1 1 auto;
  }

}
.buttonIconButtonLargeWhiteDanger:pressed {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(232, 231, 228);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 1 1 auto;
  }

}
.buttonIconButtonMediumWhiteDanger:pressed {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(232, 231, 228);
  border-radius: 4px;

  .icon {
    align-self: center;
    height: 1rem;
    flex: 0 0 1rem;
  }

}
.buttonTextButtonLargeWhiteDanger:pressed {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  background-color: rgb(232, 231, 228);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextButtonMediumWhiteDanger:pressed {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: rgb(232, 231, 228);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextButtonLargeWhiteBasic {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  background-color: rgb(245, 245, 245);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextButtonLargeWhiteBasic:hover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  background-color: rgb(232, 231, 228);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextButtonMediumWhiteBasic {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: rgb(245, 245, 245);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextButtonLargeWhiteBasic:disabled {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  background-color: rgb(245, 245, 245);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextButtonMediumWhiteBasic:disabled {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: rgb(245, 245, 245);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextButtonLargeWhiteBasic:focused {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  background-color: rgb(245, 245, 245);
  border: 2px solid rgb(30, 30, 30);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextButtonMediumWhiteBasic:focused {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: rgb(245, 245, 245);
  border: 2px solid rgb(30, 30, 30);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextButtonMediumWhiteBasic:hover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: rgb(232, 231, 228);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonIconButtonLargeWhiteBasic {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(245, 245, 245);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 1 1 auto;
  }

}
.buttonIconButtonMediumWhiteBasic {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(245, 245, 245);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 1 1 auto;
  }

}
.buttonIconButtonSmallWhiteBasic {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgb(245, 245, 245);
  border-radius: 4px;

  .icon {
    align-self: center;
    height: 1.5rem;
    flex: 0 0 1.5rem;
  }

}
.buttonIconButtonSmallNoFillBasic {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  .icon {
    align-self: center;
    height: 1.5rem;
    flex: 0 0 1.5rem;
  }

}
.buttonIconButtonSmallWhiteBasic:hover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgb(232, 231, 228);
  border-radius: 4px;

  .icon {
    align-self: center;
    height: 1.5rem;
    flex: 0 0 1.5rem;
  }

}
.buttonIconButtonSmallNoFillBasic:hover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgb(232, 231, 228);
  border-radius: 4px;

  .icon {
    align-self: center;
    height: 1.5rem;
    flex: 0 0 1.5rem;
  }

}
.buttonIconButtonSmallWhiteBasic:pressed {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgb(232, 231, 228);
  border-radius: 4px;

  .icon {
    align-self: center;
    height: 1.5rem;
    flex: 0 0 1.5rem;
  }

}
.buttonIconButtonSmallNoFillBasic:pressed {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgb(232, 231, 228);
  border-radius: 4px;

  .icon {
    align-self: center;
    height: 1.5rem;
    flex: 0 0 1.5rem;
  }

}
.buttonIconButtonSmallWhiteBasic:focused {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgb(245, 245, 245);
  border: 2px solid rgb(30, 30, 30);
  border-radius: 4px;

  .icon {
    align-self: center;
    height: 1.5rem;
    flex: 0 0 1.5rem;
  }

}
.buttonIconButtonSmallNoFillBasic:focused {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 2px solid rgb(30, 30, 30);
  border-radius: 4px;

  .icon {
    align-self: center;
    height: 1.5rem;
    flex: 0 0 1.5rem;
  }

}
.buttonIconButtonSmallWhiteBasic:disabled {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgb(245, 245, 245);
  border-radius: 4px;

  .icon {
    align-self: center;
    height: 1.5rem;
    flex: 0 0 1.5rem;
  }

}
.buttonIconButtonSmallNoFillBasic:disabled {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  .icon {
    align-self: center;
    height: 1.5rem;
    flex: 0 0 1.5rem;
  }

}
.buttonIconButtonLargeWhiteBasic:disabled {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(245, 245, 245);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 1 1 auto;
  }

}
.buttonIconButtonMediumWhiteBasic:disabled {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(245, 245, 245);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 1 1 auto;
  }

}
.buttonIconButtonLargeWhiteBasic:focused {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(245, 245, 245);
  border: 2px solid rgb(30, 30, 30);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 1 1 auto;
  }

}
.buttonIconButtonMediumWhiteBasic:focused {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(245, 245, 245);
  border: 2px solid rgb(30, 30, 30);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 1 1 auto;
  }

}
.buttonIconButtonLargeWhiteBasic:hover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(232, 231, 228);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 1 1 auto;
  }

}
.buttonIconButtonMediumWhiteBasic:hover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(232, 231, 228);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 1 1 auto;
  }

}
.buttonIconButtonLargeWhiteBasic:pressed {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(232, 231, 228);
  border-radius: 4px;

  .icon {
    align-self: stretch;
    flex: 1 1 auto;
  }

}
.buttonIconButtonMediumWhiteBasic:pressed {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: rgb(232, 231, 228);
  border-radius: 4px;

  .icon {
    align-self: center;
    height: 1rem;
    flex: 0 0 1rem;
  }

}
.buttonTextButtonLargeWhiteBasic:pressed {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  background-color: rgb(232, 231, 228);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
.buttonTextButtonMediumWhiteBasic:pressed {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: rgb(232, 231, 228);
  border-radius: 4px;

  .text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

}
