.logo {

  .logo {
  }

  .vector {
    width: 100%;
    height: 100%;
  }

}