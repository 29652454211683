.mainMenu {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05000000074505806);

  .mainMenuHeader {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.625rem 0.625rem 0.625rem 1.25rem;
    > * {
      margin-left: 0.75rem;
    }
    align-self: stretch;
    flex: 0 0 3.75rem;
  }

  .divider {
    height: 0.0625rem;
    align-self: stretch;
    flex: 0 0 auto;
  }

  .mainMenuItemList {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0rem 0.5rem 0rem 0.5rem;
    align-self: flex-start;
    width: 14rem;
    flex: 0 0 9rem;
  }

}