.inputWithStepper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-left: 0.25rem;
  }
  background-color: rgb(245, 245, 245);
  border-radius: 4px;

  .input {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    > * {
      margin-top: 0.25rem;
    }
    align-self: flex-start;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .stepDown {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    flex: 0 0 auto;
  }

  .stepUp {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    flex: 0 0 auto;
  }

}
.inputWithStepper:selected {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-left: 0.25rem;
  }
  background-color: rgb(245, 245, 245);
  border: 1px solid rgb(0, 117, 225);
  border-radius: 4px;

  .input {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    > * {
      margin-top: 0.25rem;
    }
    align-self: flex-start;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .stepDown {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    flex: 0 0 auto;
  }

  .stepUp {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    flex: 0 0 auto;
  }

}
.inputWithStepper:error {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-left: 0.25rem;
  }
  background-color: rgb(245, 245, 245);
  border: 1px solid rgb(216, 36, 0);
  border-radius: 4px;

  .input {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    > * {
      margin-top: 0.25rem;
    }
    align-self: flex-start;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .stepDown {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    flex: 0 0 auto;
  }

  .stepUp {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    flex: 0 0 auto;
  }

}
.inputWithStepper:disabled {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-left: 0.25rem;
  }
  background-color: rgb(245, 245, 245);
  border-radius: 4px;

  .input {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    > * {
      margin-top: 0.25rem;
    }
    align-self: flex-start;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .stepDown {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    flex: 0 0 auto;
  }

  .stepUp {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    flex: 0 0 auto;
  }

}