.textTitle1DarkMediumLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.5rem;
    text-align: left;
    color: rgb(30, 30, 30);
  }

}
.textTitle1GreyMediumLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.5rem;
    text-align: left;
    color: rgb(117, 117, 117);
  }

}
.textTitle2DarkMediumLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2rem;
    text-align: left;
    color: rgb(30, 30, 30);
  }

}
.textTitle2GreyMediumLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2rem;
    text-align: left;
    color: rgb(117, 117, 117);
  }

}
.textTitle3DarkMediumLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 2rem;
    text-align: left;
    color: rgb(30, 30, 30);
  }

}
.textTitle3GreyMediumLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 2rem;
    text-align: left;
    color: rgb(117, 117, 117);
  }

}
.textTitle4DarkMediumLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(30, 30, 30);
  }

}
.textTitle4GreyMediumLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(117, 117, 117);
  }

}
.textSubtitle1DarkMediumLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(30, 30, 30);
  }

}
.textSubtitle1GreyMediumLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(117, 117, 117);
  }

}
.textSubtitle2DarkMediumLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(30, 30, 30);
  }

}
.textSubtitle2GreyMediumLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(117, 117, 117);
  }

}
.textParagraph1DarkLargeLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(30, 30, 30);
  }

}
.textParagraph1GreyLargeLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(117, 117, 117);
  }

}
.textParagraph3DarkSmallLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    text-align: left;
    color: rgb(30, 30, 30);
  }

}
.textParagraph3GreySmallLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    text-align: left;
    color: rgb(117, 117, 117);
  }

}
.textParagraph3DisabledSmallLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    text-align: left;
    color: rgb(189, 189, 189);
  }

}
.textParagraph2DarkMediumLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(30, 30, 30);
  }

}
.textParagraph2GreyMediumLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(117, 117, 117);
  }

}
.textCaption1DarkXSLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    text-align: left;
    color: rgb(30, 30, 30);
  }

}
.textCaption1GreyXSLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    text-align: left;
    color: rgb(117, 117, 117);
  }

}
.textTitle1DarkMediumRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.5rem;
    text-align: right;
    color: rgb(30, 30, 30);
  }

}
.textTitle1GreyMediumRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.5rem;
    text-align: right;
    color: rgb(117, 117, 117);
  }

}
.textTitle2DarkMediumRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2rem;
    text-align: right;
    color: rgb(30, 30, 30);
  }

}
.textTitle2GreyMediumRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2rem;
    text-align: right;
    color: rgb(117, 117, 117);
  }

}
.textTitle3DarkMediumRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 2rem;
    text-align: right;
    color: rgb(30, 30, 30);
  }

}
.textTitle3GreyMediumRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 2rem;
    text-align: right;
    color: rgb(117, 117, 117);
  }

}
.textTitle4DarkMediumRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: right;
    color: rgb(30, 30, 30);
  }

}
.textTitle4GreyMediumRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: right;
    color: rgb(117, 117, 117);
  }

}
.textSubtitle1DarkMediumRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5rem;
    text-align: right;
    color: rgb(30, 30, 30);
  }

}
.textSubtitle1GreyMediumRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5rem;
    text-align: right;
    color: rgb(117, 117, 117);
  }

}
.textSubtitle2DarkMediumRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: right;
    color: rgb(30, 30, 30);
  }

}
.textSubtitle2GreyMediumRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: right;
    color: rgb(117, 117, 117);
  }

}
.textParagraph1DarkLargeRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: right;
    color: rgb(30, 30, 30);
  }

}
.textParagraph1GreyLargeRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: right;
    color: rgb(117, 117, 117);
  }

}
.textParagraph3DarkSmallRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    text-align: right;
    color: rgb(30, 30, 30);
  }

}
.textParagraph3GreySmallRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    text-align: right;
    color: rgb(117, 117, 117);
  }

}
.textParagraph3DisabledSmallRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    text-align: right;
    color: rgb(189, 189, 189);
  }

}
.textParagraph2DarkMediumRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: right;
    color: rgb(30, 30, 30);
  }

}
.textParagraph2GreyMediumRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: right;
    color: rgb(117, 117, 117);
  }

}
.textCaption1DarkXSRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    text-align: right;
    color: rgb(30, 30, 30);
  }

}
.textCaption1GreyXSRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    text-align: right;
    color: rgb(117, 117, 117);
  }

}
.textTitle1DarkMediumCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.5rem;
    text-align: center;
    color: rgb(30, 30, 30);
  }

}
.textTitle1GreyMediumCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.5rem;
    text-align: center;
    color: rgb(117, 117, 117);
  }

}
.textTitle2DarkMediumCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2rem;
    text-align: center;
    color: rgb(30, 30, 30);
  }

}
.textTitle2GreyMediumCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2rem;
    text-align: center;
    color: rgb(117, 117, 117);
  }

}
.textTitle3DarkMediumCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 2rem;
    text-align: center;
    color: rgb(30, 30, 30);
  }

}
.textTitle3GreyMediumCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 2rem;
    text-align: center;
    color: rgb(117, 117, 117);
  }

}
.textTitle4DarkMediumCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: center;
    color: rgb(30, 30, 30);
  }

}
.textTitle4GreyMediumCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: center;
    color: rgb(117, 117, 117);
  }

}
.textSubtitle1DarkMediumCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5rem;
    text-align: center;
    color: rgb(30, 30, 30);
  }

}
.textSubtitle1GreyMediumCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5rem;
    text-align: center;
    color: rgb(117, 117, 117);
  }

}
.textSubtitle1DisabledMediumLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(189, 189, 189);
  }

}
.textSubtitle1DisabledMediumRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5rem;
    text-align: right;
    color: rgb(189, 189, 189);
  }

}
.textSubtitle1DisabledMediumCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5rem;
    text-align: center;
    color: rgb(189, 189, 189);
  }

}
.textSubtitle2DarkMediumCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: center;
    color: rgb(30, 30, 30);
  }

}
.textSubtitle2GreyMediumCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: center;
    color: rgb(117, 117, 117);
  }

}
.textParagraph1DarkLargeCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: center;
    color: rgb(30, 30, 30);
  }

}
.textParagraph1GreyLargeCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: center;
    color: rgb(117, 117, 117);
  }

}
.textParagraph3DarkSmallCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    text-align: center;
    color: rgb(30, 30, 30);
  }

}
.textParagraph3GreySmallCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    text-align: center;
    color: rgb(117, 117, 117);
  }

}
.textParagraph3DisabledSmallCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    text-align: center;
    color: rgb(189, 189, 189);
  }

}
.textParagraph2DarkMediumCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: center;
    color: rgb(30, 30, 30);
  }

}
.textParagraph2GreyMediumCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: center;
    color: rgb(117, 117, 117);
  }

}
.textCaption1DarkXSCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    text-align: center;
    color: rgb(30, 30, 30);
  }

}
.textCaption1GreyXSCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    text-align: center;
    color: rgb(117, 117, 117);
  }

}
.textTitle1WhiteMediumLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.5rem;
    text-align: left;
    color: rgb(255, 255, 255);
  }

}
.textTitle2WhiteMediumLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2rem;
    text-align: left;
    color: rgb(255, 255, 255);
  }

}
.textTitle3WhiteMediumLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 2rem;
    text-align: left;
    color: rgb(255, 255, 255);
  }

}
.textTitle4WhiteMediumLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(255, 255, 255);
  }

}
.textSubtitle1WhiteMediumLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(255, 255, 255);
  }

}
.textSubtitle2WhiteMediumLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(255, 255, 255);
  }

}
.textParagraph1WhiteLargeLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(255, 255, 255);
  }

}
.textParagraph3WhiteSmallLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    text-align: left;
    color: rgb(255, 255, 255);
  }

}
.textParagraph2WhiteMediumLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(255, 255, 255);
  }

}
.textCaption1WhiteXSLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    text-align: left;
    color: rgb(255, 255, 255);
  }

}
.textTitle1WhiteMediumRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.5rem;
    text-align: right;
    color: rgb(255, 255, 255);
  }

}
.textTitle2WhiteMediumRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2rem;
    text-align: right;
    color: rgb(255, 255, 255);
  }

}
.textTitle3WhiteMediumRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 2rem;
    text-align: right;
    color: rgb(255, 255, 255);
  }

}
.textTitle4WhiteMediumRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: right;
    color: rgb(255, 255, 255);
  }

}
.textSubtitle1WhiteMediumRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5rem;
    text-align: right;
    color: rgb(255, 255, 255);
  }

}
.textSubtitle2WhiteMediumRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: right;
    color: rgb(255, 255, 255);
  }

}
.textParagraph1WhiteLargeRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: right;
    color: rgb(255, 255, 255);
  }

}
.textParagraph3WhiteSmallRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    text-align: right;
    color: rgb(255, 255, 255);
  }

}
.textParagraph2WhiteMediumRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: right;
    color: rgb(255, 255, 255);
  }

}
.textCaption1WhiteXSRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    text-align: right;
    color: rgb(255, 255, 255);
  }

}
.textTitle1WhiteMediumCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.5rem;
    text-align: center;
    color: rgb(255, 255, 255);
  }

}
.textTitle2WhiteMediumCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2rem;
    text-align: center;
    color: rgb(255, 255, 255);
  }

}
.textTitle3WhiteMediumCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 2rem;
    text-align: center;
    color: rgb(255, 255, 255);
  }

}
.textTitle4WhiteMediumCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: center;
    color: rgb(255, 255, 255);
  }

}
.textSubtitle1WhiteMediumCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5rem;
    text-align: center;
    color: rgb(255, 255, 255);
  }

}
.textSubtitle2WhiteMediumCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: center;
    color: rgb(255, 255, 255);
  }

}
.textParagraph1WhiteLargeCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: center;
    color: rgb(255, 255, 255);
  }

}
.textParagraph3WhiteSmallCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    text-align: center;
    color: rgb(255, 255, 255);
  }

}
.textParagraph2WhiteMediumCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: center;
    color: rgb(255, 255, 255);
  }

}
.textCaption1WhiteXSCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    text-align: center;
    color: rgb(255, 255, 255);
  }

}
.textTitle1BrandMediumLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.5rem;
    text-align: left;
    color: rgb(0, 117, 225);
  }

}
.textTitle2BrandMediumLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2rem;
    text-align: left;
    color: rgb(0, 117, 225);
  }

}
.textTitle3BrandMediumLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 2rem;
    text-align: left;
    color: rgb(0, 117, 225);
  }

}
.textTitle4BrandMediumLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(0, 117, 225);
  }

}
.textSubtitle1BrandMediumLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(0, 117, 225);
  }

}
.textSubtitle2BrandMediumLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(0, 117, 225);
  }

}
.textParagraph1BrandLargeLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(0, 117, 225);
  }

}
.textParagraph3BrandSmallLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    text-align: left;
    color: rgb(0, 117, 225);
  }

}
.textParagraph2BrandMediumLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(0, 117, 225);
  }

}
.textCaption1BrandXSLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    text-align: left;
    color: rgb(0, 117, 225);
  }

}
.textTitle1BrandMediumRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.5rem;
    text-align: right;
    color: rgb(0, 117, 225);
  }

}
.textTitle2BrandMediumRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2rem;
    text-align: right;
    color: rgb(0, 117, 225);
  }

}
.textTitle3BrandMediumRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 2rem;
    text-align: right;
    color: rgb(0, 117, 225);
  }

}
.textTitle4BrandMediumRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: right;
    color: rgb(0, 117, 225);
  }

}
.textSubtitle1BrandMediumRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5rem;
    text-align: right;
    color: rgb(0, 117, 225);
  }

}
.textSubtitle2BrandMediumRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: right;
    color: rgb(0, 117, 225);
  }

}
.textParagraph1BrandLargeRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: right;
    color: rgb(0, 117, 225);
  }

}
.textParagraph3BrandSmallRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    text-align: right;
    color: rgb(0, 117, 225);
  }

}
.textParagraph2BrandMediumRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: right;
    color: rgb(0, 117, 225);
  }

}
.textCaption1BrandXSRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    text-align: right;
    color: rgb(0, 117, 225);
  }

}
.textTitle1BrandMediumCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.5rem;
    text-align: center;
    color: rgb(0, 117, 225);
  }

}
.textTitle2BrandMediumCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2rem;
    text-align: center;
    color: rgb(0, 117, 225);
  }

}
.textTitle3BrandMediumCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 2rem;
    text-align: center;
    color: rgb(0, 117, 225);
  }

}
.textTitle4BrandMediumCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: center;
    color: rgb(0, 117, 225);
  }

}
.textSubtitle1BrandMediumCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5rem;
    text-align: center;
    color: rgb(0, 117, 225);
  }

}
.textSubtitle2BrandMediumCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: center;
    color: rgb(0, 117, 225);
  }

}
.textParagraph1BrandLargeCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: center;
    color: rgb(0, 117, 225);
  }

}
.textParagraph3BrandSmallCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    text-align: center;
    color: rgb(0, 117, 225);
  }

}
.textParagraph2BrandMediumCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: center;
    color: rgb(0, 117, 225);
  }

}
.textCaption1BrandXSCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    text-align: center;
    color: rgb(0, 117, 225);
  }

}
.textTitle1DangerMediumLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.5rem;
    text-align: left;
    color: rgb(216, 36, 0);
  }

}
.textTitle2DangerMediumLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2rem;
    text-align: left;
    color: rgb(216, 36, 0);
  }

}
.textTitle3DangerMediumLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 2rem;
    text-align: left;
    color: rgb(216, 36, 0);
  }

}
.textTitle4DangerMediumLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(216, 36, 0);
  }

}
.textSubtitle1DangerMediumLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(216, 36, 0);
  }

}
.textSubtitle2DangerMediumLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(216, 36, 0);
  }

}
.textParagraph1DangerLargeLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(216, 36, 0);
  }

}
.textParagraph3DangerSmallLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    text-align: left;
    color: rgb(216, 36, 0);
  }

}
.textParagraph2DangerMediumLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(216, 36, 0);
  }

}
.textCaption1DangerXSLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    text-align: left;
    color: rgb(216, 36, 0);
  }

}
.textTitle1DangerMediumRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.5rem;
    text-align: right;
    color: rgb(216, 36, 0);
  }

}
.textTitle2DangerMediumRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2rem;
    text-align: right;
    color: rgb(216, 36, 0);
  }

}
.textTitle3DangerMediumRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 2rem;
    text-align: right;
    color: rgb(216, 36, 0);
  }

}
.textTitle4DangerMediumRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: right;
    color: rgb(216, 36, 0);
  }

}
.textSubtitle1DangerMediumRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5rem;
    text-align: right;
    color: rgb(216, 36, 0);
  }

}
.textSubtitle2DangerMediumRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: right;
    color: rgb(216, 36, 0);
  }

}
.textParagraph1DangerLargeRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: right;
    color: rgb(216, 36, 0);
  }

}
.textParagraph3DangerSmallRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    text-align: right;
    color: rgb(216, 36, 0);
  }

}
.textParagraph2DangerMediumRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: right;
    color: rgb(216, 36, 0);
  }

}
.textCaption1DangerXSRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    text-align: right;
    color: rgb(216, 36, 0);
  }

}
.textTitle1DangerMediumCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.5rem;
    text-align: center;
    color: rgb(216, 36, 0);
  }

}
.textTitle2DangerMediumCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2rem;
    text-align: center;
    color: rgb(216, 36, 0);
  }

}
.textTitle3DangerMediumCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 2rem;
    text-align: center;
    color: rgb(216, 36, 0);
  }

}
.textTitle4DangerMediumCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: center;
    color: rgb(216, 36, 0);
  }

}
.textSubtitle1DangerMediumCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5rem;
    text-align: center;
    color: rgb(216, 36, 0);
  }

}
.textSubtitle2DangerMediumCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: center;
    color: rgb(216, 36, 0);
  }

}
.textParagraph1DangerLargeCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: center;
    color: rgb(216, 36, 0);
  }

}
.textParagraph3DangerSmallCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1rem;
    text-align: center;
    color: rgb(216, 36, 0);
  }

}
.textParagraph2DangerMediumCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: center;
    color: rgb(216, 36, 0);
  }

}
.textCaption1DangerXSCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Inter', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    text-align: center;
    color: rgb(216, 36, 0);
  }

}